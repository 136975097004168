import { popupCenter } from '@/util/Popup.js'
import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import DeveloperService from '@/services/developer.service'

export default {
  name: 'PluginSalesForce',
  components: {
    HeaderTopDashboard,
  },
  data () {
    return {
      docTranslations: ['es', 'en', 'pt'],
      salesForce: {},
    }
  },
  computed: {},
  watch: {},
  created () {
    this.getPluginModulesAndIntegrations()
  },
  mounted () {},
  methods: {
    getPluginModulesAndIntegrations () {
      const user = JSON.parse(localStorage.getItem('user'))
      const lang = this.$i18n.locale || user.lang
      const selectedTranslation = this.docTranslations.includes(lang) ? lang : 'en'
      const params = {
        lang: selectedTranslation,
      }
      DeveloperService.getPluginModulesAndIntegrations(params)
        .then(
          (response) => {
            this.salesForce = response.integrations.marketing_cloud
          },
          (error) => {
            console.log(error)
          },
        )
    },
    viewDoc (url, title) {
      popupCenter(url, title, 1000, 600)
    },
    viewUrl (url, title) {
      popupCenter(url, title, 1000, 600)
    },
  },
}
